/* Add Inter font globally via Google Fonts CDN with proper font-display setting */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap&font-display=swap');

/* Ensure page uses Inter by default */
html, body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent; /* Remove tap highlight on mobile */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  touch-action: manipulation; /* Improve input handling on touch devices */
  min-height: 100%;
  height: 100%;
}

html {
  height: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

#root {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Explicitly set all text to Inter */
* {
  font-family: 'Inter', sans-serif;
}

/* Button pulse animation */
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.8;
  }
}

/* Text pulse animation */
@keyframes pulseText {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
}

/* Horizontal loading animation */
@keyframes horizontalLoading {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

/* Apply these animations to specific classes */
.pulse-text {
  animation: pulseText 1.5s infinite;
}

.pulse {
  animation: pulse 1.5s infinite;
}

.fade-in {
  animation: fadeIn 0.5s forwards;
}

/* Element fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Horizontal gradient loading effect for "Analyzing job requirements..." */
.loading-text {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.5) 25%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0.1) 100%
  );
  background-size: 200% 100%;
  animation: horizontalLoading 2s infinite linear;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent !important;
  text-shadow: 0px 0px 20px rgba(255, 255, 255, 0.1);
  font-weight: 500;
}

.app-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

.loader-circle {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #1976d2;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 16px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
